import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiSearch, BiAddToQueue, BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line, RiEyeLine } from "react-icons/ri";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DataTable from "react-data-table-component";
import Callaxios from "./Callaxios";
import JoditEditor from "jodit-react";

export default function Blog() {
  const [modal, setmodal] = useState(false);
  const [title, settitle] = useState("");
  const [searchvalue, setsearchvalue] = useState("");
  const [content, setContent] = useState();
  const [viewContent, setviewContent] = useState(false);
  const [thumbnailImage, setthumbnailImage] = useState("");
  const [bannerImage, setbannerImage] = useState("");
  const [blogData, setblogData] = useState([]);
  const [selectblog, setselectblog] = useState();
  const [htmlContent, sethtmlContent] = useState();

  useEffect(() => {
    Getblogs();
  }, []);
  const notify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      theme: "dark",
    });
  const notifyerror = (msg) =>
    toast.error(msg, {
      position: "top-right",
      theme: "dark",
    });

  const Getblogs = async () => {
    try {
      let data = await Callaxios("get", "blogs/blog/");
      if (data.status === 200) {
        setblogData(data.data);
      }
    } catch (error) {
      notifyerror("Something went wrong");
    }
  };

  const addBlog = async (e) => {
    e.preventDefault();

    if (!thumbnailImage) {
      notifyerror("Thumbnail image is required");
      return;
    }
    if (!bannerImage) {
      notifyerror("Banner image is required");
      return;
    }
    if (!content) {
      notifyerror("Content is required");
      return;
    }

    let msg;
    const form_data = new FormData();
    if (thumbnailImage instanceof File) {
      form_data.append("image", thumbnailImage);
    }
    if (bannerImage instanceof File) {
      form_data.append("banner", bannerImage);
    }
    form_data.append("blog_name", title);
    form_data.append("contant", content);

    if (selectblog) {
      form_data.append("id", selectblog.id);
      msg = "Updated Successfully";
    } else {
      msg = "Added Successfully";
    }
    try {
      let data = await Callaxios("post", "blogs/blog/", form_data);

      if (data.data.Status === 200) {
        Getblogs();
        notify(msg);
        setmodal(!modal);
      }
    } catch (error) {
      console.error(error);
      notifyerror("Something went wrong");
    }
  };

  const deleteblog = async (itmid) => {
    try {
      let data = await Callaxios("delete", `blogs/blog/`, { id: itmid });

      if (data.status === 200) {
        notify("Deleted Successfully");
        Getblogs();
      }
    } catch (error) {
      notifyerror("Something went wrong");
    }
  };

  const submitdelete = (itemid) => {
    confirmAlert({
      title: <h1 className="confirm-title">Confirmation</h1>,
      message: `Are you sure to delete this ?`,
      buttons: [
        {
          label: "No",
        },
        {
          label: "Yes",
          onClick: () => deleteblog(itemid),
        },
      ],
    });
  };
  const edittheme = (itm) => {
    setallnull();
    setselectblog(itm);
    settitle(itm.blog_name);
    setthumbnailImage(itm.image);
    setbannerImage(itm.banner);
    setContent(itm.contant);
    setmodal(!modal);
  };

  const setallnull = () => {
    setselectblog("");
    settitle("");
    setthumbnailImage("");
    setbannerImage("");
    setContent("");
  };

  const rowNumber = (row) =>
    blogData
      .filter((t) =>
        t.blog_name.toUpperCase().includes(searchvalue.toUpperCase())
      )
      .indexOf(row) + 1;
  const columns = [
    {
      name: <div>#</div>,
      selector: (row) => rowNumber(row),
      width: "50px",
    },
    {
      name: "Blog Title",
      selector: (itm) => <div>{itm.blog_name}</div>,
      width: "20%",
    },
    {
      name: "Thumbnail Image",
      selector: (itm) => (
        <div className="d-flex-col text-center">
          <img
            src={itm.image}
            width={70}
            className="img-thumbnail"
            alt="layout images"
          />
        </div>
      ),
    },
    {
      name: "Banner Image",
      selector: (itm) => (
        <div className="d-flex-col text-center">
          <img
            src={itm.banner}
            width={70}
            className="img-thumbnail"
            alt="layout images"
          />
        </div>
      ),
    },
    {
      name: "Content",
      selector: (itm) => (
        <div className="d-flex">
          <div>
            <button
              onClick={() =>
                setviewContent(!viewContent) & sethtmlContent(itm.contant)
              }
              className="btn btn-info btn-xs "
            >
              <RiEyeLine
                size={15}
                style={{ marginBottom: "2px", marginRight: "1px" }}
              />
              View
            </button>
          </div>
        </div>
      ),
      width: "150px",
    },

    {
      name: "Action",
      selector: (itm) => (
        <div className="d-flex">
          <div>
            <button
              onClick={() => edittheme(itm)}
              className="btn btn-warning btn-xs "
            >
              <BiEdit size={15} />
            </button>
          </div>
          <div className="ml-5" style={{ marginLeft: "2px" }}>
            <button
              onClick={() => submitdelete(itm.id)}
              className="btn btn-danger btn-xs"
            >
              <RiDeleteBin6Line size={15} />
            </button>
          </div>
        </div>
      ),
      width: "150px",
    },
  ];

  const customStyles = {
    cells: {
      style: {
        border: "0.5px solid #f5f2f2 ",
      },
    },

    headCells: {
      style: {
        minHeight: "40px",
        border: "0.5px solid #e8e2e2 ",
        borderTopWidth: "1.5px",
      },
    },
    filter: {
      style: {
        border: "1px solid gray",
      },
    },
  };

  // configuration to upload image from device
  const config = {
    uploader: {
      insertImageAsBase64URI: true,
      url: "/upload",
      process: (response) => {
        return response.url;
      },
    },
  };

  return (
    <div className="page-wrapper p-3 mt-5">
      <ToastContainer />
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row ">
                <div className="col-6">
                  <h6 className="card-title text-start text-bold">Blogs</h6>
                  <div className="text-start">
                    <button
                      onClick={() => setmodal(!modal) & setallnull()}
                      className="btn btn-success btn-sm"
                    >
                      <BiAddToQueue size={20} />
                      Add
                    </button>
                  </div>
                </div>
                <div className="col-6">
                  <form className="search-form ml-auto">
                    <div className="input-group">
                      <div className="input-group-text">
                        <BiSearch />
                      </div>
                      <input
                        onChange={(e) =>
                          e.target.value.charAt(0) !== " "
                            ? setsearchvalue(e.target.value)
                            : ""
                        }
                        value={searchvalue}
                        type="text"
                        className="form-control"
                        id="navbarForm"
                        placeholder="Search here..."
                      />
                    </div>
                  </form>
                </div>
              </div>

              <div className="table-responsive pt-3">
                <DataTable
                  pagination
                  columns={columns}
                  data={blogData.filter((t) =>
                    t.blog_name
                      .toUpperCase()
                      .includes(searchvalue.toUpperCase())
                  )}
                  defaultSortField="id"
                  defaultSortAsc={false}
                  paginationRowsPerPageOptions={[10, 20, 50, 100]}
                  customStyles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal "
        id="exampleModalCenter"
        tabIndex={1}
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
        style={
          modal === true
            ? { display: "block", paddingRight: 17 }
            : { display: "none" }
        }
      >
        <div className="modal-dialog modal-dialog-centered modal-lg box-shadow-blank">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Add Blog
              </h5>
              <button
                onClick={() => setmodal(!modal)}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              />
            </div>
            <form className="forms-sample" onSubmit={(e) => addBlog(e)}>
              <div className="modal-body">
                <div className="row text-start">
                  <div className="mb-3 col-md-12 col-12">
                    <label htmlFor="userEmail" className="form-label ">
                      Title
                    </label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        const isFirstSpace = inputValue.indexOf(" ") === 0;

                        if (isFirstSpace) {
                          settitle(inputValue.substring(1));
                        } else {
                          settitle(inputValue);
                        }
                      }}
                      value={title}
                      type="text"
                      required
                      className="form-control"
                      placeholder="Product Title"
                    />
                  </div>

                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="userEmail" className="form-label ">
                      Thumbnail Image
                    </label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="file"
                      onChange={(e) => setthumbnailImage(e.target.files[0])}
                      value={""}
                      className="form-control"
                      placeholder="image"
                    />
                    {thumbnailImage ? (
                      <div className="m-2">
                        <img
                          className="rounded image-size"
                          src={
                            thumbnailImage instanceof File
                              ? URL.createObjectURL(thumbnailImage)
                              : thumbnailImage
                          }
                          alt="img"
                          height="auto"
                          width="auto"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-6 col-12">
                    <label htmlFor="userEmail" className="form-label ">
                      Banner Image
                    </label>
                    <span style={{ color: "red" }}>*</span>
                    <input
                      type="file"
                      onChange={(e) => setbannerImage(e.target.files[0])}
                      value={""}
                      className="form-control"
                      placeholder="image"
                    />
                    {bannerImage ? (
                      <div className="m-2">
                        <img
                          className="rounded image-size"
                          src={
                            bannerImage instanceof File
                              ? URL.createObjectURL(bannerImage)
                              : bannerImage
                          }
                          alt="img"
                          height="auto"
                          width="auto"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3 col-md-12 col-12">
                    <label htmlFor="userEmail" className="form-label ">
                      Content
                    </label>
                    <span style={{ color: "red" }}>*</span>
                    <JoditEditor
                      value={content}
                      config={config}
                      tabIndex={1}
                      onBlur={(newContent) => setContent(newContent)}
                    />
                  </div>
                </div>
                <div />
              </div>
              <div className="modal-footer">
                <button
                  onClick={() => setmodal(!modal)}
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* conent view */}
      <div
        className="modal "
        id="exampleModalCenter"
        tabIndex={1}
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
        style={
          viewContent === true
            ? { display: "block", paddingRight: 17 }
            : { display: "none" }
        }
      >
        <div className="modal-dialog modal-dialog-centered modal-lg box-shadow-blank">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
                Content
              </h5>
              <button
                onClick={() =>
                  setviewContent(!viewContent) & sethtmlContent("")
                }
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="btn-close"
              />
            </div>

            <div className="modal-body">
              <div className="row text-start">
                <div
                  className="mb-3 col-md-12 col-12"
                  dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
              </div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
